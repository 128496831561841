<template>
  <div
    v-infinite-scroll="handleInfiniteOnLoad"
    class="demo-infinite-container"
    :infinite-scroll-disabled="isAllLoaded"
    :infinite-scroll-distance="100"
  >
    <a-list :data-source="list">
      <a-list-item slot="renderItem" slot-scope="item" class="collection-block" @click="handleOk(item.id)">
        <a-list-item-meta>
          <div slot="title" class="title">{{ item.name }}</div>
          <a-avatar
            slot="avatar"
            :src="item.images && item.images.length > 0 ? item.images[0] : require('@/assets/images/logo-default.png')"
          />
        </a-list-item-meta>
      </a-list-item>
      <div v-if="isLoading && !isAllLoaded" class="demo-loading-container">
        <a-spin />
      </div>
    </a-list>
  </div>
</template>

<script>
import page from '@/mixins/page'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'CollectionFolder',

  directives: { infiniteScroll },

  data () {
    return {
    }
  },

  computed: {
    // 获取列表的参数
    args () {
      return {
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mixins: [page],

  beforeMount () {
    this.getFolderList()
  },

  methods: {
    // 获取数据
    getFolderList () {
      this.api.getFolderListByUser(this.args).then(res => {
        this.list = this.list.concat(res.data.getFolderListByUser.data)
        this.total = res.data.getFolderListByUser.total
        this.isLoading = false
        this.isAllLoaded = this.list.length >= this.total
      }).catch(() => {
        this.isLoading = false
      })
    },

    // 确认
    handleOk (id) {
      this.$emit('ok', id)
    },

    // 触底刷新加载数据
    handleInfiniteOnLoad () {
      if (this.isAllLoaded) return
      this.pageNum++
      this.isLoading = true
      this.getFolderList()
    }
  }
}
</script>

<style scoped>
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
  width: 300px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.collection-block {
  cursor: pointer;
}
.title:hover {
  color: var(--blue-color) !important;
}
</style>
