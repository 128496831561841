<template>
  <div class="container">
    <!-- 主图、标题和标签 -->
    <div class="detail-box" v-if="data.id">
      <img alt="image" :src='data.image.url'
           :style="{ width: `${data.image.width}px`, height: `${data.image.height}px` }" />
      <div class="info">
        <div class="action-bar">
          <div>
            <a-popover v-model="collectionFolderVisible" trigger="click" placement="bottom">
              <template slot="content" style="height: 100px">
                <!-- 收藏夹列表 -->
                <collection-folder @ok="handleAddToFolder" />
              </template>
              <a-icon type="star" />
            </a-popover>
            <a-icon type="share-alt" class="share-btn" @click="handleShare" />
          </div>
          <div class="ops-line text-not-selected">
            <div @click="handleImagesPack">
              <a-icon type="file-zip" style="margin-right: 5px" />
              <span>下载图包</span>
            </div>
            <div @click="handleOrder">
              <a-icon type="gift" style="margin-right: 5px" />
              <span>获取样品</span>
            </div>
<!--            <a-dropdown :trigger="['click']" placement="bottomCenter">-->
<!--              <a-icon type="ellipsis" @click="e => e.preventDefault()" />-->
<!--              <a-menu slot="overlay">-->
<!--&lt;!&ndash;                <a-menu-item @click="handleDownloadMainImage">&ndash;&gt;-->
<!--&lt;!&ndash;                  <a-icon type="download" />下载主图&ndash;&gt;-->
<!--&lt;!&ndash;                </a-menu-item>&ndash;&gt;-->
<!--                <a-menu-item @click="handleImagesPack">-->
<!--                  <a-icon type="file-zip" />下载图包-->
<!--                </a-menu-item>-->
<!--                <a-menu-divider />-->
<!--                <a-menu-item @click="handleOrder">-->
<!--                  <a-icon type="gift" />获取样品-->
<!--                </a-menu-item>-->
<!--              </a-menu>-->
<!--            </a-dropdown>-->
          </div>
        </div>
        <div class="title">{{data.title}}</div>
        <div class="tag-list">
          <a-tag class="tag text-not-selected" v-for="(item, index) of data.tags" :key="index">{{item.name}}</a-tag>
        </div>
      </div>
    </div>

    <!-- 文本内容 -->
    <div class="content-block">
      <div v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import CollectionFolder from './CollectionFolder'

export default {
  name: 'StoneDetail',

  data () {
    return {
      // 石材数据
      data: {},
      // 图片固定宽度，px
      imageWidth: 550,

      // 收藏夹弹窗
      collectionFolderVisible: false
    }
  },

  props: {
    stoneID: String
  },

  components: { CollectionFolder },

  beforeMount () {
    this.addLog()
    this.getStone()
  },

  beforeDestroy () {
    window.onresize = null
  },

  methods: {
    // 获取数据
    getStone () {
      this.api.getStone(this.stoneID).then(res => {
        const data = res.data.getStone
        const ratio = data.image.width / this.imageWidth
        data.image.width = this.imageWidth
        data.image.height = data.image.height / ratio
        this.data = data
      })
    },

    // 打日志
    addLog () {
      this.api.addLog({
        imageID: 0,
        stoneID: this.stoneID
      }).then()
    },

    // 分享
    handleShare () {
      const clipboard = new Clipboard('.share-btn', {
        text: () => {
          return 'https://www.nextstone.vip' + this.$route.fullPath
        }
      })

      clipboard.on('success', () => {
        this.$message.success('成功复制链接，可以分享给其他人啦！')
        clipboard.destroy()
      })

      clipboard.on('error', () => {
        this.$message.error('复制链接失败')
        clipboard.destroy()
      })
    },

    // 下载主图
    handleDownloadMainImage () {
      window.open(this.data.image.url)
    },

    // 下载图包
    handleImagesPack () {
      this.api.getImagePack({
        stoneID: this.data.id
      }).then(res => {
        if (res.data.getImagePack.isSuccess) {
          this.$message.success('图包已发送到您的邮箱，请注意查收')
          return
        }
        this.$message.warn('暂无图包')
      })
    },

    // 获取样品
    handleOrder () {
      this.$store.commit('setStoneID', this.data.id)
      this.$router.push({
        path: '/add-order'
      })
    },

    // 将石材添加到收藏夹
    handleAddToFolder (folderID) {
      this.api.addCollectionByUser({
        stoneID: this.data.id,
        folderID
      }).then(res => {
        if (res.data.addCollectionByUser.id) {
          this.$message.success('添加成功')
          this.collectionFolderVisible = false
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  display: grid;
  row-gap: 10px;
  max-width: 1000px;
}

.detail-box {
  display: grid;
  grid-template-columns: auto 350px;
  column-gap: 20px;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  background-color: #fff;
}
.detail-box > img {
  border-radius: 10px;
}

.action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.action-bar i {
  cursor: pointer;
  font-size: 23px;
  color: #61687c;
}
.action-bar i:not(:last-child) {
  margin-right: 10px;
}
.action-bar i:hover {
  color: var(--blue-color);
}
.title {
  font-size: 38px;
  font-weight: bolder;
  margin-top: 10px;
}
.tag-list {
  margin-top: 10px;
}
.tag {
  margin-bottom: 10px;
  cursor: pointer;
}

.ops-line {
  display: flex;
  align-items: center;
}
.ops-line > div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ops-line > div:last-child {
  margin-left: 10px;
}
.ops-line span {
  font-size: 14px;
}

.content-block {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  background-color: #fff;
}
.content-block >>> p {
  margin-bottom: 0 !important;
}
.content-block >>> img {
  width: 100%;
}
</style>
